<template>
  <gf-content title="Manage Users" subtitle="Add users and manage their details and permissions">
    <template #toolbar>
      <el-input style="width: 250px" class="mr-4" v-model="search"></el-input>
      <gf-button type="primary" @click="addUser">
        <template slot="icon">
          <span class="svg-icon svg-icon-white svg-icon-sm">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
              </g>
            </svg>
          </span>
        </template>
        Add
      </gf-button>
    </template>
    <gf-table :data="users" v-loading="loading" @row-click="readUser">
      <el-table-column label="User" min-width="200">
        <template #default="slot">
          <div class="user">
            <div class="mr-3 image">
              <img v-if="slot.row.avatar_url" :src="slot.row.avatar_url">
              <img v-else src="@/assets/avatar.jpg">
            </div>
            <div class="details">
              <span class="text-dark-75 font-weight-bold font-size-sm name">{{ $StringFormat(slot.row.fname + ' ' + slot.row.lname, 18) }}</span>
              <span class="text-muted font-weight-bold email">{{ $StringFormat(slot.row.email, 18) }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="username" min-width="200" prop="username">
      </el-table-column>
      <el-table-column label="Groups" min-width="200">
        <template #default="slot">
          {{ $StringFormat(slot.row.groups.map(g => g.name).join(', '), 20) }}
        </template>
      </el-table-column>
      <el-table-column label="Position" min-width="100" prop="position.name">
      </el-table-column>
      <el-table-column label="Status" min-width="100" align="center">
        <template #default="slot">
          <gf-label inline pill light type="primary" v-if="slot.row.is_active">Active</gf-label>
          <gf-label inline pill light type="dark" v-else>Inactive</gf-label>
        </template>
      </el-table-column>
      <el-table-column label="Created At" min-width="150">
        <template #default="slot">
          {{ $DateFormat(slot.row.created_at) }}
        </template>
      </el-table-column>
    </gf-table>
    <template #footer>
      <gf-pagination :pagination="pagination" @change="getUsers"></gf-pagination>
    </template>
  </gf-content>
</template>

<style lang="scss" scoped>
.user {
  display: flex;
  flex-direction: row;
  align-items: center;

  .image {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
  }
}
</style>

<script>
// Services
import UserService from '@/services/v1/User'

// Lodash
import { debounce } from 'lodash'

export default {
  props: {
    active: Number
  },
  data () {
    return {
      users: [],
      positions: [],
      search: '',
      filter: {},

      // ui
      loading: false,

      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      this.getUsers()
    }, 400)
  },
  methods: {
    // navigation
    addUser () {
      this.$router.push({ name: 'users-add' })
    },
    readUser (row) {
      this.$router.push({ name: 'users-view', params: { id: row.id } })
    },

    async getUsers () {
      try {
        this.loading = true
        const usService = new UserService()
        const response = await usService.list(this.pagination.page, null, this.filter, null)
        this.users = response.data.rows

        if (response.data.count === 0 && response.data.rows.length !== 0) {
        } else this.pagination.total = response.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.$emit('update:active', 1)

    await this.getUsers()
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Settings' },
      { title: 'Company Details' },
      { title: 'Users' }
    ])
  }
}
</script>
